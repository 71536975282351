@charset "UTF-8";

a,
.primary {
  color: #f25c19;
}

hr {
  opacity: 0.15;
}

.lds-ripple {
  left: calc(50% - 32px);
}

.lds-ripple .lds-pos {
  border-color: #f25c19;
}

body {
  background-color: #f5f5f5;
}

body #main-wrapper {
  max-width: 80vw;
  margin: 30px auto;
  border-radius: 35px;
}

body #main-wrapper .page-wrapper {
  margin-left: 0 !important;
  z-index: 0;
}

body #main-wrapper aside {
  width: 100%;
  position: static;
}

body label {
  font-size: 12px;
  color: #7c7c8d;
  margin-bottom: 4px !important;
}

body input,
body select,
body textarea {
  border-radius: 10px !important;
  z-index: 10;
}

body .input-group input {
  border-radius: 10px 0 0 10px !important;
}

body .input-group span {
  border-radius: 0 10px 10px 0 !important;
}

body input[type="time"] {
  background-color: #fff;
  text-align: center;
}

body input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  display: none;
}

body .btn-primary {
  background-color: #f25c19;
  border-color: #f25c19;
  color: #fff;
  outline: none;
}

body .btn-primary:hover,
body .btn-primary:focus {
  border-color: #d44f11;
  background-color: #d44f11;
}

body .btn-secondary {
  background-color: #fff;
  color: #f25c19;
  border-color: #f25c19;
  outline: none;
}

body .btn-secondary:hover,
body .btn-secondary:focus {
  border-color: #d44f11;
  color: #fff;
  background-color: #d44f11;
}

body .invalid-tooltip {
  border-radius: 10px;
  position: static;
  width: 100%;
  background-color: #fff;
  color: rgba(252, 75, 108, 0.9);
}

body .toastie {
  position: fixed;
  right: 70px;
  bottom: 70px;
  z-index: 999;
  min-width: 300px;
}

body .toastie .card {
  border-width: 0;
  border-radius: 20px;
  background-color: #f25c19;
  color: #fff;
}

body .toastie .card h4 {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
  font-weight: 800;
  color: #fff;
}

body .toastie .card p {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
  margin: 0;
}

body .toastie .card a {
  font-weight: 800;
  color: #fff;
}

body .toastie.alert .card {
  padding-left: 10px;
  overflow: hidden;
  border-radius: 20px;
  background-color: #fff;
  color: #0f0c0c;
}

body .toastie.alert .card h4 {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
  font-weight: 800;
  color: #f25c19;
  position: relative;
}

body .toastie.alert .card h4:before {
  display: block;
  content: "";
  background-color: #f25c19;
  width: 30px;
  height: 150px;
  position: absolute;
  left: -50px;
  top: -36px;
}

body .toastie.alert .card p {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
  margin: 0;
}

body .toastie.alert .card a {
  font-weight: 800;
  color: #282828;
}

body header {
  background: url("../assets/images/header-bg.jpg") #fff no-repeat right center/contain !important;
  height: 90px !important;
  padding: 10px 0;
}

body header .navbar-header {
  border: 0 !important;
  position: absolute;
}

body header .navbar-header .navbar-brand {
  padding: 0 25px !important;
}

body header #headernav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 30px;
  width: 100%;
  -ms-flex-item-align: end;
  align-self: end;
  list-style: none;
}

body header #headernav li.selected a {
  color: #f25c19 !important;
  position: relative;
  overflow: visible;
  font-weight: 800;
}

body header #headernav li.selected a::after {
  content: "○";
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  bottom: -15px;
  font-size: 16px;
  color: #f25c19;
}

body header #headernav li a {
  color: #000;
}

body .page-breadcrumb {
  padding: 25px 25px 0 25px;
}

body footer {
  background-color: #f25c19;
  position: relative;
  height: 40px;
  z-index: -1;
}

body footer #footerPicture {
  position: absolute;
  top: -225px;
  right: 0px;
  z-index: 0;
  height: 225px;
  width: auto;
  opacity: 0.4;
}

body footer img {
  position: absolute;
  right: 15px;
  top: -30px;
  width: 60px;
  height: 60px;
}

body .modal-content {
  border-radius: 10px;
}

body .modal-content .modal-header {
  min-height: 100px;
}

body .table {
  --max-lines: 3;
  --lh: 1.2rem;
}

body .table ul {
  list-style: none;
  margin-bottom: 0;
}

body .table td,
body .table th {
  vertical-align: middle;
  padding: 1rem 0.8rem;
  line-height: --lh;
}

body .table tr {
  cursor: pointer;
}

body .table tr td:not(:last-child) {
  white-space: nowrap;
}

body .table th {
  color: #b5b7c0;
  font-size: 0.8em;
  font-weight: 200;
  white-space: nowrap;
}

body .table .truncate-overflow {
  position: relative;
  line-height: var(--lh);
  min-width: 230px;
  max-height: calc(var(--lh) * var(--max-lines));
  overflow: hidden;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  padding-right: 1rem;
  /* space for ellipsis */
}

body .table tr:hover .truncate-overflow {
  max-height: initial !important;
}

body .table .truncate-overflow::before {
  position: absolute;
  content: "...";
  inset-block-end: 0;
  /* "bottom" */
  inset-inline-end: 0;
  /* "right" */
}

body .table .truncate-overflow::after {
  content: "";
  position: absolute;
  inset-inline-end: 0;
  /* "right" */
  width: 1rem;
  height: 1rem;
}

body .table tr.disabled {
  opacity: 0.6;
}

body .table .user-comment {
  min-width: 300px;
}

body .table .program-cover {
  width: 45px;
  height: 45px;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 10px;
}

body .table .devices {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: start;
}

body .table .devices ul {
  padding-left: 10px;
}

body .table .devices div {
  border: 2px solid #f25c19;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

body .table .additional-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 10px;
}

body .table .additional-info .green-dot {
  display: block;
  background-color: green;
  width: 6px;
  height: 6px;
  border-radius: 10px;
  float: left;
  margin: 7px 5px 0 0;
}

body .hours-data {
  color: #d1d1d1;
}

body .hours-data h5 {
  margin-bottom: 20px;
}

body .hours-data span {
  color: #000;
}

body .hours-data .weekday {
  color: #b5b7c0;
  width: 100px;
}

body .mt--1 {
  margin-top: -4px;
}

body .m-icon {
  padding: 0px 10px 0 0;
  width: auto;
}

body .m-icon:hover {
  background-color: transparent;
}

body #filtry .d-flex {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

body #my-image {
  max-width: 150px;
  max-height: 150px;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  border-radius: 10px;
}

body .croppie-container.hidden,
body #custom-up-btn.hidden,
body #use.hidden {
  display: none !important;
}

body button#use {
  background-color: #f25c19;
  border-color: #f25c19;
  color: #fff;
  outline: none;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  border-radius: 4px;
  -webkit-transition: color 0.15s;
  transition: color 0.15s;
}

body input[type="file"] {
  display: none;
}

body .custom-file-upload {
  border: 1px solid #ccc;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  border-radius: 4px;
  display: inline-block;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
}

body div.form-select {
  border-radius: 10px;
  cursor: pointer;
}

body .selectlist {
  position: relative;
  top: 4px;
}

body .selectlist .list {
  position: absolute;
  width: 100%;
  background-color: #fff;
  list-style: none;
  padding-left: 0;
  -webkit-box-shadow: 0 5px 15px rgba(209, 209, 209, 0.4);
  box-shadow: 0 5px 15px rgba(209, 209, 209, 0.4);
  border-radius: 10px;
  border: 1px solid #e8eef2;
}

body .selectlist .list li {
  padding: 5px 10px;
  margin-left: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

body .selectlist .list li:not(:first-child) {
  border-top: 1px solid #e8eef2;
}

body .selectlist .list li:hover {
  background-color: #f6f8fb;
}

body .selectlist .list li:last-child {
  border-radius: 0 0 20px 20px;
}

body .status {
  padding: 10px !important;
}

body .status span.active,
body .status span.inactive {
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 10px;
  margin: 0 10px;
}

body .status span.inactive {
  background-color: #b5b7c0;
}

body .status span.active {
  background-color: #f25c19;
}

body .page-item .page-link {
  color: #f25c19;
}

body .page-item.active .page-link {
  background-color: #f25c19;
  border-color: #f25c19;
}

body .topbar .nav-toggler,
body .topbar .topbartoggler {
  padding: 0px 25px;
  margin-right: 20px;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  border-radius: 50px;
}

body p.small {
  font-size: 11px;
  color: #7c7c8d !important;
}

body .form-select.is-valid,
body .was-validated .form-select:valid,
body .form-control.is-valid,
body .was-validated .form-control:valid {
  border-color: #e8eef2;
  background: none;
}

body .empty-list {
  padding: 40px 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: url("../assets/images/empty.png") no-repeat center center/contain;
  border-radius: 80px;
  max-height: 120px;
  margin: 0 auto;
}

body .empty-list img {
  max-height: 50px;
}

body .empty-list p {
  color: #ccc;
  margin: 10px 0;
}

.login-wrapper {
  background-color: #f25c19;
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.login-wrapper .login-container {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  min-height: 70vh;
  width: 60vw;
  min-width: 350px;
  background-color: #fff;
  border-radius: 30px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}

.login-wrapper .login-container .login-decor {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-item-align: stretch;
      align-self: stretch;
  background: url("../assets/images/login-bg.png") no-repeat center center/cover;
}

.login-wrapper .login-container .login-decor img {
  margin: 80px auto 20px;
  max-height: 18%;
  height: 18%;
}

.login-wrapper .pass-reminder {
  text-align: right;
  font-size: 11px;
  color: #7c7c8d;
}

.login-wrapper .pass-reminder:hover {
  color: #f25c19 !important;
}

.login-wrapper .alt-login {
  font-size: 11px;
  color: #b5b7c0;
  text-align: center;
  position: relative;
  margin: 20px 0;
}

.login-wrapper .alt-login span {
  background-color: #fff;
  z-index: 9;
  position: relative;
  padding: 0 10px;
}

.login-wrapper .alt-login:before {
  content: "";
  position: absolute;
  width: 100%;
  border-bottom: 1px solid #b5b7c0;
  bottom: 7px;
  left: 0;
  z-index: 0;
}

.login-wrapper .alt-login-btns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
}

.login-wrapper .alt-login-btns button {
  padding: 10px;
  -ms-flex-preferred-size: 33.33%;
      flex-basis: 33.33%;
}

.login-wrapper .btn-light {
  background-color: #f5f5f5;
  border: 1px solid #b5b7c0;
  border-radius: 10px;
}

.login-wrapper .btn-light:hover {
  background-color: #fff;
  border-color: #f25c19;
}


.devices-list {
  padding: 0;
}

.devices-list li {
  list-style-type: none;
  padding: 5px 0;
}

.devices-list li img {
  max-width: 18px;
  margin-left: 10px;
  margin-top: -3px;
}

.devices-list li img:hover {
  cursor: pointer;
}

.range-slider {
  margin: 3px 0 0 0;
}

.range-slider {
  width: 100%;
}

.range-slider__range {
  -webkit-appearance: none;
  width: calc(100% - (73px));
  height: 10px;
  border-radius: 5px;
  background: #d7dcdf;
  outline: none;
  padding: 0;
  margin: 0;
}

.range-slider__range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #2c3e50;
  cursor: pointer;
  -webkit-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
}

.range-slider__range::-webkit-slider-thumb:hover {
  background: #f25c19;
}

.range-slider__range:active::-webkit-slider-thumb {
  background: #f25c19;
}

.range-slider__range::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border: 0;
  border-radius: 50%;
  background: #2c3e50;
  cursor: pointer;
  -webkit-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
}

.range-slider__range::-moz-range-thumb:hover {
  background: #f25c19;
}

.range-slider__range:active::-moz-range-thumb {
  background: #f25c19;
}

.range-slider__range:focus::-webkit-slider-thumb {
  -webkit-box-shadow: 0 0 0 3px #fff, 0 0 0 6px #f25c19;
  box-shadow: 0 0 0 3px #fff, 0 0 0 6px #f25c19;
}

.form-group.gap-2 .form-content {
  text-align: center;
}

.form-content {
  width: 100%;
  display: block;
  color: #000;
}

.homescreen .table tbody td,
.homescreen .table tbody th {
  max-height: 56px !important;
  height: 56px;
  padding-block: 5px;
}

.stat-tiles {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

.stat-tiles .stat {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: url("../assets/images/bg-card.svg") no-repeat center center/cover;
  border-radius: 20px;
  padding: 20px 10px 20px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-preferred-size: calc(50% - 10px) !important;
  flex-basis: calc(50% - 10px) !important;
}

@media screen and (max-width: 1400px) {
  .stat-tiles .stat {
    -ms-flex-preferred-size: 100% !important;
    flex-basis: 100% !important;
  }
}

.stat-tiles .stat .tile-icon {
  max-width: 60px;
  margin-right: 15px;
}

.stat-tiles .stat .tile-text {
  padding-top: 15px;
}

.stat-tiles .stat .tile-text h3,
.stat-tiles .stat .tile-text h4 {
  margin-bottom: 4px;
}

.stat-tiles .stat .tile-text .subheading {
  font-size: 12px;
}

.stat-tiles .stat .tile-text .heading {
  font-size: 18px;
}

.stat-tiles .stat .tile-text .heading span {
  color: #7c7c8d;
  font-size: 14px;
  margin-left: 12px;
  position: relative;
}

.stat-tiles .stat .tile-text .heading span:before {
  display: inline-block;
  content: "";
  width: 5px;
  height: 5px;
  position: absolute;
  left: -8px;
  bottom: 7px;
  border-radius: 10px;
  background-color: #7c7c8d;
}

.stat-tiles .stat .tile-text .details {
  font-size: 11px;
}

.range-slider__value {
  display: inline-block;
  position: relative;
  width: 60px;
  color: #fff;
  line-height: 20px;
  text-align: center;
  border-radius: 3px;
  background: #2c3e50;
  padding: 5px 10px;
  margin-left: 8px;
}

.range-slider__value:after {
  position: absolute;
  top: 8px;
  left: -7px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-right: 7px solid #2c3e50;
  border-bottom: 7px solid transparent;
  content: "";
}

::-moz-range-track {
  background: #d7dcdf;
  border: 0;
}

input::-moz-focus-inner,
input::-moz-focus-outer {
  border: 0;
}

.thumbSlider {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #282828;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

.thumbSlider:hover {
  background: #f25c19;
}

@media screen and (max-width: 992px) {
  body #main-wrapper {
    max-width: 100vw;
    margin: 0;
    border-radius: 0;
  }

  .page-breadcrumb {
    padding: 20px;
  }

  .page-breadcrumb .btn {
    margin-top: 15px;
    width: 100%;
  }

  #filtry .d-flex {
    gap: 0 !important;
  }

  #filtry .d-flex .btn {
    width: 100%;
  }

  .table tr td:last-child {
    min-width: 300px;
  }

  body header {
    background-size: cover !important;
  }

  body header #headernav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: absolute;
    width: 500px;
    max-width: 60vw;
    background-color: #fff;
    right: -100%;
    top: -20px;
    height: 100vh;
    padding: 100px 0 0 0;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    -webkit-box-shadow: 0 0 50px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.15);
    gap: 5px !important;
    -webkit-box-pack: start !important;
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }

  body header #headernav li {
    padding-left: 40px;
  }

  body header #headernav li:hover {
    background-color: rgba(242, 92, 25, 0.2);
  }

  body header #headernav li a {
    width: 100%;
    padding: 10px 0;
  }

  body header #headernav li.selected a::after {
    left: -15px !important;
    bottom: 10px !important;
  }

  body header .navbar-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  footer #footerPicture {
    display: none;
  }

  .login-wrapper .login-container .login-decor img {
    width: 180px;
    max-height: initial;
    margin: 20px auto 80px;
  }

  .show-sidebar header #headernav {
    right: 0;
  }

  .row .row.col-12 {
    padding-right: 0;
    padding-left: 0 !important;
  }

  .row .row.col-12 .col-12 {
    padding-right: 0 !important;
  }
}

