/*
Template Name: Admin Template
Author: Wrappixel

File: scss
*/

@import 'comments';
@import 'steamline';
@import 'feeds';
@import 'profile';