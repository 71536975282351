$primary: #f25c19;
$primarydark: #d44f11;
$silver: #b5b7c0;
a,
.primary {
  color: $primary;
}
hr {
  opacity: 0.15;
}
.lds-ripple {
  top: calc(50% - 32px);
  left: calc(50% - 32px);
  .lds-pos {
    border-color: $primary;
  }
}
body {
  background-color: #f5f5f5;
  #main-wrapper {
    max-width: 80vw;
    margin: 30px auto;
    border-radius: 35px;
    .page-wrapper {
      margin-left: 0 !important;
      z-index: 0;
    }
    aside {
      width: 100%;
      position: static;
    }
  }
  label {
    font-size: 12px;
    color: #7c7c8d;
    margin-bottom: 4px !important;
  }
  input,
  select,
  textarea {
    border-radius: 10px !important;
    z-index: 10;
  }
  .input-group {
    input {
      border-radius: 10px 0 0 10px !important;
    }
    span {
      border-radius: 0 10px 10px 0 !important;
    }
  }
  input[type="time"] {
    background-color: #fff;
    text-align: center;
    &::-webkit-calendar-picker-indicator {
      background: none;
      display: none;
    }
  }

  .btn-primary {
    background-color: $primary;
    border-color: $primary;
    color: #fff;
    outline: none;
    &:hover,
    &:focus {
      border-color: $primarydark;
      background-color: $primarydark;
    }
  }
  .btn-secondary {
    background-color: #fff;
    color: $primary;
    border-color: $primary;
    outline: none;
    &:hover,
    &:focus {
      border-color: $primarydark;
      color: #fff;
      background-color: $primarydark;
    }
  }
  .invalid-tooltip {
    border-radius: 10px;
    position: static;
    width: 100%;
    background-color: #fff;
    color: rgba(252, 75, 108, 0.9);
  }
  .toastie {
    position: fixed;
    right: 70px;
    bottom: 70px;
    z-index: 999;
    min-width: 300px;
    .card {
      border-width: 0;
      h4 {
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
        font-weight: 800;
        color: #fff;
      }
      p {
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
        margin: 0;
      }
      a {
        font-weight: 800;
        color: #fff;
      }
      border-radius: 20px;
      background-color: $primary;
      color: #fff;
    }
    &.alert {
      .card {
        padding-left: 10px;
        overflow: hidden;
        h4 {
          text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
          font-weight: 800;
          color: $primary;
          position: relative;
          &:before {
            display: block;
            content: "";
            background-color: $primary;
            width: 30px;
            height: 150px;
            position: absolute;
            left: -50px;
            top: -36px;
          }
        }
        p {
          text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.05);
          margin: 0;
        }
        a {
          font-weight: 800;
          color: #282828;
        }
        border-radius: 20px;
        background-color: #fff;
        // border-left: 5px solid $primary;
        color: #0f0c0c;
      }
    }
  }

  header {
    background: url("../../assets/images/header-bg.jpg") #fff no-repeat right
      center / contain !important;
    height: 90px !important;
    padding: 20px 0;
    .navbar-header {
      border: 0 !important;
      position: absolute;
      .navbar-brand {
        padding: 0 25px !important;
      }
    }
    #headernav {
      display: flex;
      justify-content: center;
      gap: 30px;
      width: 100%;
      align-self: end;
      list-style: none;

      li {
        &.selected a {
          color: #f25c19 !important;
          position: relative;
          overflow: visible;
          font-weight: 800;
          &::after {
            content: "○";
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
            bottom: -15px;
            font-size: 16px;
            color: $primary;
          }
        }
        a {
          color: #000;
        }
      }
    }
  }
  .page-breadcrumb {
    padding: 25px 25px 0 25px;
  }

  footer {
    background-color: $primary;
    position: relative;
    height: 40px;
    z-index: -1;
    #footerPicture {
      position: absolute;
      top: -285px;
      right: 0px;
      z-index: 0;
      height: 285px;
      width: auto;
      opacity: 1;
    }
    img {
      position: absolute;
      right: 15px;
      top: -30px;
      width: 60px;
      height: 60px;
    }
  }
  .modal-content {
    border-radius: 10px;
    .modal-header {
      min-height: 100px;
    }
  }
  .table {
    --max-lines: 3;
    --lh: 1.2rem;
    ul {
      list-style: none;
      margin-bottom: 0;
    }
    td,
    th {
      vertical-align: middle;
      padding: 1rem 0.8rem;
      line-height: --lh;
    }
    tr {
      cursor: pointer;
      td:not(:last-child) {
        white-space: nowrap;
      }
    }
    th {
      color: $silver;
      font-size: 0.8em;
      font-weight: 200;
      white-space: nowrap;
    }

    .truncate-overflow {
      position: relative;
      line-height: var(--lh);
      min-width: 230px;
      max-height: calc(var(--lh) * var(--max-lines));
      overflow: hidden;
      transition: all 0.5s ease-in-out;
      padding-right: 1rem; /* space for ellipsis */
    }
    tr:hover .truncate-overflow {
      max-height: initial !important;
    }
    .truncate-overflow::before {
      position: absolute;
      content: "...";
      inset-block-end: 0; /* "bottom" */
      inset-inline-end: 0; /* "right" */
    }
    .truncate-overflow::after {
      content: "";
      position: absolute;
      inset-inline-end: 0; /* "right" */
      width: 1rem;
      height: 1rem;
    }
    tr.disabled {
      opacity: 0.6;
    }
    .user-comment {
      min-width: 300px;
    }

    .program-cover {
      width: 45px;
      height: 45px;
      object-fit: cover;
      border-radius: 10px;
    }
    .devices {
      display: flex;
      align-items: center;
      justify-content: start;
      ul {
        padding-left: 10px;
      }
      div {
        border: 2px solid $primary;
        border-radius: 50px;
        width: 30px;
        height: 30px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .additional-info {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      .green-dot {
        display: block;
        background-color: green;
        width: 6px;
        height: 6px;
        border-radius: 10px;
        float: left;
        margin: 7px 5px 0 0;
      }
    }
  }
  .hours-data {
    color: #d1d1d1;
    h5 {
      margin-bottom: 20px;
    }
    span {
      color: #000;
    }
    .weekday {
      color: #b5b7c0;
      width: 100px;
    }
  }
  .mt--1 {
    margin-top: -4px;
  }
  .m-icon {
    padding: 0px 10px 0 0;
    width: auto;
    &:hover {
      background-color: transparent;
    }
  }
  #filtry {
    .d-flex {
      flex-wrap: wrap;
    }
  }
  #my-image {
    max-width: 150px;
    max-height: 150px;
    flex: 1 1 100%;
    border-radius: 10px;
  }
  .croppie-container,
  #custom-up-btn,
  #use {
    &.hidden {
      display: none !important;
    }
  }

  button#use {
    background-color: #f25c19;
    border-color: #f25c19;
    color: #fff;
    outline: none;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.9375rem;
    border-radius: 4px;
    transition: color 0.15s;
  }

  input[type="file"] {
    display: none;
  }
  .custom-file-upload {
    border: 1px solid #ccc;
    padding: 0.375rem 0.75rem;
    font-size: 0.9375rem;
    border-radius: 4px;
    display: inline-block;
    cursor: pointer;
    font-weight: 400;
    line-height: 1.5;
  }
  div.form-select {
    border-radius: 10px;
    cursor: pointer;
  }
  .selectlist {
    position: relative;
    top: 4px;
    .list {
      position: absolute;
      width: 100%;
      background-color: #fff;
      list-style: none;
      padding-left: 0;
      box-shadow: 0 5px 15px rgba(209, 209, 209, 0.4);
      border-radius: 10px;
      border: 1px solid #e8eef2;
      li {
        padding: 5px 10px;
        margin-left: 0;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        &:not(:first-child) {
          border-top: 1px solid #e8eef2;
        }
        &:hover {
          background-color: #f6f8fb;
        }
        &:last-child {
          border-radius: 0 0 20px 20px;
        }
      }
    }
  }

  .status {
    padding: 10px !important;
    span {
      &.active,
      &.inactive {
        display: block;
        width: 15px;
        height: 15px;
        border-radius: 10px;
        margin: 0 10px;
      }
      &.inactive {
        background-color: #b5b7c0;
      }
      &.active {
        background-color: $primary;
      }
    }
  }
  .page-item {
    .page-link {
      color: $primary;
    }
    &.active .page-link {
      background-color: $primary;
      border-color: $primary;
    }
  }
  .topbar .nav-toggler,
  .topbar .topbartoggler {
    padding: 0px 25px;
    margin-right: 20px;
    // background-color: #ffffff;
    width: min-content;
    border-radius: 50px;
  }
  p.small {
    font-size: 11px;
    color: #7c7c8d !important;
  }
  .form-select.is-valid,
  .was-validated .form-select:valid,
  .form-control.is-valid,
  .was-validated .form-control:valid {
    border-color: #e8eef2;
    background: none;
  }

  .empty-list {
    padding: 40px 60px;
    display: flex;
    align-items: center;
    // background: rgba(232, 238, 242,.3);
    background: url("/assets/images/empty.png") no-repeat center center /
      contain;
    border-radius: 80px;
    max-height: 120px;
    margin: 0 auto;
    img {
      max-height: 50px;
    }
    p {
      color: #ccc;
      margin: 10px 0;
    }
  }
}
.login-wrapper {
  background-color: $primary;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .login-container {
    flex-wrap: wrap;
    min-height: 70vh;
    width: 60vw;
    min-width: 350px;

    background-color: #fff;
    border-radius: 30px;
    overflow: hidden;
    display: flex;
    align-items: stretch;
    .login-decor {
      display: flex;
      align-self: stretch;
      background: url("../../assets/images/login-bg.svg") no-repeat center
        center / cover;

      img {
        margin: 50px 0 0 30px;
        max-height: 18%;
      }
    }
  }
  .pass-reminder {
    text-align: right;
    font-size: 11px;
    color: #7c7c8d;
    &:hover {
      color: $primary !important;
    }
  }
  .alt-login {
    font-size: 11px;
    color: #b5b7c0;
    text-align: center;
    position: relative;
    margin: 20px 0;
    span {
      background-color: #fff;
      z-index: 9;
      position: relative;
      padding: 0 10px;
    }
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      border-bottom: 1px solid #b5b7c0;
      bottom: 7px;
      left: 0;
      z-index: 0;
    }
  }
  .alt-login-btns {
    display: flex;
    gap: 10px;
    button {
      padding: 10px;
      flex-basis: 33.33%;
    }
  }
  .btn-light {
    background-color: #f5f5f5;
    border: 1px solid #b5b7c0;
    border-radius: 10px;
    &:hover {
      background-color: #fff;
      border-color: $primary;
    }
  }
}
.devices-list {
  padding: 0;
  li {
    list-style-type: none;
    padding: 5px 0;
    img {
      max-width: 18px;
      margin-left: 10px;
      margin-top: -3px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

// Base Colors
$shade-10: #2c3e50 !default;
$shade-1: #d7dcdf !default;
$shade-0: #fff !default;

.range-slider {
  margin: 3px 0 0 0;
}

// Range Slider
$range-width: 100% !default;

$range-handle-color: $shade-10 !default;
$range-handle-color-hover: $primary !default;
$range-handle-size: 20px !default;

$range-track-color: $shade-1 !default;
$range-track-height: 10px !default;

$range-label-color: $shade-10 !default;
$range-label-width: 60px !default;

.range-slider {
  width: $range-width;
}

.range-slider__range {
  -webkit-appearance: none;
  width: calc(100% - (#{$range-label-width + 13px}));
  height: $range-track-height;
  border-radius: 5px;
  background: $range-track-color;
  outline: none;
  padding: 0;
  margin: 0;

  // Range Handle
  &::-webkit-slider-thumb {
    appearance: none;
    width: $range-handle-size;
    height: $range-handle-size;
    border-radius: 50%;
    background: $range-handle-color;
    cursor: pointer;
    transition: background 0.15s ease-in-out;

    &:hover {
      background: $range-handle-color-hover;
    }
  }

  &:active::-webkit-slider-thumb {
    background: $range-handle-color-hover;
  }

  &::-moz-range-thumb {
    width: $range-handle-size;
    height: $range-handle-size;
    border: 0;
    border-radius: 50%;
    background: $range-handle-color;
    cursor: pointer;
    transition: background 0.15s ease-in-out;

    &:hover {
      background: $range-handle-color-hover;
    }
  }
  &:active::-moz-range-thumb {
    background: $range-handle-color-hover;
  }

  // Focus state
  &:focus {
    &::-webkit-slider-thumb {
      box-shadow: 0 0 0 3px $shade-0, 0 0 0 6px $primary;
    }
  }
}
.form-group.gap-2 {
  .form-content {
    text-align: center;
  }
}
.form-content {
  width: 100%;
  display: block;
  color: #000;
}
.homescreen {
  .table {
    tbody {
      td,
      th {
        max-height: 56px !important;
        height: 56px;
        padding-block: 5px;
      }
    }
  }
}
.stat-tiles {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  .stat {
    align-items: center;
    background: url("../../assets/images/bg-card.svg") no-repeat center center /
      cover;
    border-radius: 20px;
    padding: 20px 10px 20px 20px;
    display: flex;
    flex-basis: calc(50% - 10px) !important;
    @media screen and (max-width: 1400px) {
      flex-basis: 100% !important;
    }
    .tile-icon {
      max-width: 60px;
      margin-right: 15px;
    }
    .tile-text {
      padding-top: 15px;
      h3,
      h4 {
        margin-bottom: 4px;
      }
      .subheading {
        font-size: 12px;
      }
      .heading {
        font-size: 18px;
        span {
          color: #7c7c8d;
          font-size: 14px;
          margin-left: 12px;
          position: relative;
          &:before {
            display: inline-block;
            content: "";
            width: 5px;
            height: 5px;
            position: absolute;
            left: -8px;
            bottom: 7px;
            border-radius: 10px;
            background-color: #7c7c8d;
          }
        }
      }
      .details {
        font-size: 11px;
      }
    }
  }
}
// Range Label
.range-slider__value {
  display: inline-block;
  position: relative;
  width: $range-label-width;
  color: $shade-0;
  line-height: 20px;
  text-align: center;
  border-radius: 3px;
  background: $range-label-color;
  padding: 5px 10px;
  margin-left: 8px;

  &:after {
    position: absolute;
    top: 8px;
    left: -7px;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-right: 7px solid $range-label-color;
    border-bottom: 7px solid transparent;
    content: "";
  }
}

// Firefox Overrides
::-moz-range-track {
  background: $range-track-color;
  border: 0;
}

input::-moz-focus-inner,
input::-moz-focus-outer {
  border: 0;
}

@media screen and (max-width: 992px) {
  body #main-wrapper {
    max-width: 100vw;
    margin: 0;
    border-radius: 0;
  }
  .page-breadcrumb {
    padding: 20px;
    .btn {
      margin-top: 15px;
      width: 100%;
    }
  }
  #filtry{
    .d-flex{
      gap: 0 !important;
      .btn{
        width: 100%;
      }
    }
  }
  .table{
    tr{
      td:last-child{
        min-width: 300px;
      }
    }
  }
  body header {
    background-size: cover !important;
    #headernav {
      flex-direction: column;
      position: absolute;
      width: 500px;
      max-width: 60vw;
      background-color: #fff;
      right: -100%;
      top: -20px;
      height: 100vh;
      padding: 100px 0 0 0;
      transition: all 0.5s ease-in-out;
      box-shadow: 0 0 50px rgba(0, 0, 0, 0.15);
      gap: 5px !important;
      justify-content: flex-start !important;
      li {
        padding-left: 40px;
        &:hover {
          background-color: rgba(242, 92, 25, 0.2);
        }
        a {
          width: 100%;
          padding: 10px 0;
        }
        &.selected {
          a::after {
            left: -15px !important;
            bottom: 10px !important;
          }
        }
      }
    }
    .navbar-header {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
    }
  }
  footer {
    #footerPicture {
      display: none;
    }
  }
  .login-wrapper {
    .login-container {
      .login-decor {
        img {
          width: 200px;
          max-height: initial;
          margin: 30px auto 20px;
        }
      }
    }
  }

  .show-sidebar {
    header {
      #headernav {
        right: 0;
      }
    }
  }
  .row {
    .row.col-12 {
      padding-right: 0;
      padding-left: 0 !important;
      .col-12 {
        padding-right: 0 !important;
      }
    }
  }
}
