/*******************
 Breadcrumb and page title
*******************/

.page-breadcrumb {
  padding: 30px;
  .page-title {
    margin-bottom: 0px;
  }
  .breadcrumb {
    padding: 0px;
    margin: 0px;
    background: transparent;
    font-size: 15px;
    .breadcrumb-item {
      display: flex;
      align-items: center;
    }
    .breadcrumb-item + .breadcrumb-item::before {
      content: "\e649";
      font-family: themify;
      color: $gray-400;
      font-size: 11px;
    }
  }
}
