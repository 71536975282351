/*******************
 General Topbar
*******************/

.topbar {
  position: relative;
  z-index: 50;
  background: $topbar;
  height: $topbar-height;
  .navbar-collapse {
    padding: 0px 10px 0 0;
  }
  .top-navbar {
    min-height: $topbar-height;
    padding: 0px;
    .dropdown-toggle::after {
      display: none;
    }
    .navbar-header {
      line-height: $topbar-navlink-height;
      border-right: 1px solid $border-color;
      .navbar-brand {
        width: $sidebar-width-full;
        display: flex;
        align-items: center;
        margin: 0px;
        padding: $topbar-navbrand-padding;
        .light-logo {
          display: none;
        }
        .logo-icon {
          margin-right: 13px;
        }
      }
    }
    .navbar-nav:first-child {
      margin-left: 25px;
    }
    .navbar-nav > .nav-item > .nav-link {
      padding: $topbar-navlink-padding;
      font-size: $topbar-navlink-font-size;
      line-height: $topbar-navlink-height;
      height: $topbar-navlink-height;
      display: flex;
      align-items: center;
    }
  }
  .user-dd,
  .mailbox {
    min-width: 280px;
  }
  .nav-toggler,
  .topbartoggler {
    color: $theme-dark;
    padding: 0 15px;
    font-size: 20px;
  }
}

.app-search {
  z-index: 110;
  width: 100%;
  top: -1px;
  box-shadow: $shadow;
  display: none;
  left: 0px;
  .form-control {
    padding: 21px 15px;
    height: 70px;
    // @extend %square;
    // transition: $transitions;
    &:focus {
      border-color: transparent;
    }
  }
  .srh-btn {
    position: absolute;
    top: 23px;
    cursor: pointer;
    right: 20px;
  }
}

/*******************
Designed dropdown
******************/
.topbar {
  .dropdown-menu {
    padding-top: 0px;
    border: 0px;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);

    &.dropdown-menu-right {
      .with-arrow {
        right: 0px;
        > span {
          right: 20px;
          left: auto;
        }
      }
    }
  }
}
/*******************
Topbar Responsive
******************/

@media (max-width: 767px) {
  .topbar .top-navbar {
    .navbar-collapse.show,
    .navbar-collapse.collapsing {
      display: block;
      width: 100%;
      border-top: 1px solid $border-color;
    }
    .navbar-nav {
      flex-direction: row;
      .dropdown {
        position: static;
      }
    }
    .navbar-nav > .nav-item > .nav-link {
      padding: 0 10px;
    }
    .navbar-header {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
    }
    .dropdown-menu {
      position: absolute;
      width: 100%;
      .with-arrow {
        display: none;
      }
    }
  }
}
